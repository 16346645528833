import dayjs from 'dayjs';
import 'dayjs/locale/th';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import _ from 'lodash';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import qs from 'qs';
import { useEffect, useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { FaAngleDown, FaAngleUp, FaBars, FaGripHorizontal, FaSearch, FaTimes } from 'react-icons/fa';
import { APP_IMG, RECAPTCHA_SIT_KEY, SHOW_COMMEMORATE, SHOW_RIBBON } from 'src/constants';
import { SHOW_MEMBER } from 'src/services/member-auth/constants';
import { DATALAYER_TYPE, sendDataLayerGlobal } from 'src/utils/datalayer/send-datalayer';
import styled from 'styled-components';
const WidgetLogin = dynamic(import('src/services/member-auth/components/widget-login'));
const WidgetFollow = dynamic(import('src/components/widgets/widget-follow'));
const CardSubMenu = dynamic(import('src/components/cards/card-sub-menu'));

dayjs.extend(buddhistEra);

function useOnClickOutsideExpand(ref, handler) {
  useEffect(() => {
    const listener = event => {
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }

      handler(event);
    };

    document.addEventListener('mouseleave', listener);

    return () => {
      document.removeEventListener('mouseleave', listener);
    };
  }, [ref, handler]);
}

function useOnClickOutsideExpandSub(ref, handler) {
  useEffect(() => {
    const listener = event => {
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }

      handler(event);
    };

    document.addEventListener('mouseleave', listener);

    return () => {
      document.removeEventListener('mouseleave', listener);
    };
  }, [ref, handler]);
}

function useOnClickOutsideMoreMenu(ref, handler) {
  useEffect(() => {
    const listener = event => {
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }

      handler(event);
    };

    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [ref, handler]);
}

function useOnClickOutsideSearch(ref, handler) {
  useEffect(() => {
    const listener = event => {
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }

      handler(event);
    };

    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [ref, handler]);
}

const Header = ({ data, sessionSevId }) => {
  let newNavData = [];
  if (data === null) newNavData = new Array(11).fill('');

  const router = useRouter();
  const searchBoxRef = useRef();
  const recaptchaRef = useRef(null);

  const [toggleSearch, setToggleSearch] = useState(false);
  const [textSearch, setTextSearch] = useState(null);
  const [recaptchaBox, setRecaptchaBox] = useState(false);
  const [subToggle, setSubToggle] = useState({
    status: false,
    key: null
  });
  const [subToggleMoreMenu, setSubToggleMoreMenu] = useState({
    status: false,
    key: null
  });
  const [moreMenu, setMoreMenu] = useState(true);
  const [expandMobile, setExpandMobile] = useState(false);

  const expandBoxRef = useRef();
  const subExpandBoxRef = useRef();
  const moreMenuBoxRef = useRef();

  useOnClickOutsideExpand(expandBoxRef, async () => {
    await setSubToggle({
      status: false,
      key: null
    });
  });

  useOnClickOutsideExpandSub(subExpandBoxRef, async () => {
    await setSubToggleMoreMenu({
      status: false,
      key: null
    });
  });

  useOnClickOutsideMoreMenu(moreMenuBoxRef, async () => {
    await setMoreMenu(true);
    await setExpandMobile(false);
  });

  useOnClickOutsideSearch(searchBoxRef, async () => {
    await setToggleSearch(false);
  });

  const handleSearch = e => {
    e.preventDefault();
    setRecaptchaBox(true);
  };

  const onReCAPTCHAChange = async captchaCode => {
    if (!captchaCode) return null;

    try {
      const response = await fetch('/api/search/recaptcha', {
        method: 'POST',
        body: JSON.stringify({ textSearch, captcha: captchaCode }),
        headers: {
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        setRecaptchaBox(false);
        const serachParams = {
          q: textSearch || ''
        };

        const params = qs.stringify(serachParams, {
          encodeValuesOnly: true,
          arrayFormat: 'brackets'
        });
        window.location.href = `/search?${params}`;
      } else {
        const error = await response.json();
        throw new Error(error.message);
      }
    } catch (err) {
      alert(err?.message || 'Something went wrong');
      setRecaptchaBox(false);
    } finally {
      recaptchaRef.current = null;
      setTextSearch(null);
      setRecaptchaBox(false);
    }
  };

  let cloneNavData = [];

  if (data !== null) {
    cloneNavData = [...data];
    newNavData = cloneNavData.splice(0, 11);
  }

  const dateNow = dayjs(new Date()).locale('th').format('วันdddd ที่ D MMMM BBBB ');

  const { cid } = router.query;
  let dataNav = newNavData.filter((el) => el.nameEng === 'news');
  dataNav = dataNav[0]?.sub.concat({
    id: 0,
    nameTh: 'ข่าวทั้งหมด',
    nameEng: 'latest-news',
    link: '/category/latest-news',
    metaData: null,
    data: []
  });
  newNavData = newNavData.map((el) => {
    if (el.nameEng === 'news') {
      return { ...el, sub: dataNav.sort((a, b) => a.id - b.id) };
    }
    return el;
  });

  return (
    <>
    <div className='default-header'>
      <HeaderWrapper gray={SHOW_COMMEMORATE === 'true'} className='header-wrapper'>
      <header className='header-wrapper'>
        <div className='top-header-wrapper'>
          <div className='top-header'>
            <div className='top-header-settrade'>
              <p>{dateNow}</p>
            </div>
            {/* --------------------------------- FOLLOW --------------------------------- */}
            <WidgetFollow
              sessionSevId={sessionSevId}
              //! DATALAYER
              eventDataLayer={{
                type: DATALAYER_TYPE.TRACK_POSITION,
                router: router?.pathname,
                position: 'on:header',
                section: 'header'
              }}
              handleSearch={() => {
                setToggleSearch(!toggleSearch);
              }}
            />
          </div>
        </div>
        {/* ---------------------------------- LOGO ---------------------------------- */}
        <div className={`header-wrap ${SHOW_COMMEMORATE === 'true' ? 'back-white' : ''}`}>
          <div className='logo-header'>
            {router.asPath === '/' ? (
              <h1>
                <span>ข่าวล่าสุด วิเคราะห์ข่าวเศรษฐกิจ - กรุงเทพธุรกิจ</span>
                <a
                  href='/'
                  title='กรุงเทพธุรกิจ'
                  //! DATALAYER
                  onClick={() =>
                    sendDataLayerGlobal({
                      type: DATALAYER_TYPE.TRACK_POSITION,
                      router: router?.pathname,
                      position: 'on:header',
                      section: 'header',
                      data: {
                        title: 'logo',
                        heading: 'logo'
                      }
                    })
                  }
                >
                  <img className='logo' src={`${APP_IMG}/logo-primary.png`} alt='logo' width='100%' height='100%' />
                </a>
              </h1>
            ) : (
              <a
                href='/'
                //! DATALAYER
                onClick={() =>
                  sendDataLayerGlobal({
                    type: DATALAYER_TYPE.TRACK_POSITION,
                    router: router?.pathname,
                    position: 'on:header',
                    section: 'header',
                    data: {
                      title: 'logo',
                      heading: 'logo'
                    }
                  })
                }
              >
                {SHOW_RIBBON === 'true' && (
                  <div className='ribbon'>
                    <img src={`${APP_IMG}/logo-ribbon.png`} alt='logo' width='50px' height='50px' />
                  </div>
                )}
                <img className='logo' src={`${APP_IMG}/logo-primary.png`} alt='logo' width='100%' height='100%' />
              </a>
            )}
          </div>

          <div className='nav-header'>
            <div className='container'>
              <div className='row'>
                <div className='col-12 align-self-center'>
                  <div className='row justify-content-between'>
                    <ul className='--desktop col-12'>
                      <li
                        className={`${router.asPath === '/' ? 'active' : ''}`}
                        onMouseOver={() => {
                          setSubToggle({ status: false, key: null });
                        }}
                      >
                        <a
                          href='/'
                          aria-label='nav-desktop'
                          title='หน้าแรก'
                          //! DATALAYER
                          onClick={() =>
                            sendDataLayerGlobal({
                              type: DATALAYER_TYPE.TRACK_POSITION,
                              router: router?.pathname,
                              position: 'on:header',
                              section: 'header',
                              data: {
                                title: 'หน้าแรก',
                                heading: 'หน้าแรก'
                              }
                            })
                          }
                        >
                          หน้าแรก
                        </a>
                      </li>
                      {newNavData.map((item, i) => (
                        <li key={i} className={`${item && cid === item.nameEng ? 'active' : ''}`}>
                          {item.sub.length > 0 ? (
                            <>
                              <a
                                key={i}
                                style={{ position: 'relative' }}
                                href={`/${item.link}`}
                                title={item?.nameTh}
                                onMouseOver={() => {
                                  setSubToggle({ status: true, key: i });
                                  setSubToggleMoreMenu({ status: false, key: null });
                                }}
                                //! DATALAYER
                                onClick={() =>
                                  sendDataLayerGlobal({
                                    type: DATALAYER_TYPE.TRACK_POSITION,
                                    router: router?.pathname,
                                    position: 'on:header',
                                    section: 'header',
                                    data: {
                                      title: item?.nameTh,
                                      heading: item?.nameTh
                                    }
                                  })
                                }
                              >
                                {item.nameTh}
                                {item.sub.length > 0 && <FaAngleDown />}
                              </a>
                              {subToggle?.status && subToggle?.key === i ? (
                                <div className={`expand-sub ${i > 5 && '--right'}`} ref={expandBoxRef} onMouseLeave={() => setSubToggle({ key: null, status: false })}>
                                  <ul>
                                    <CardSubMenu
                                      data={item?.sub}
                                      heading={item?.nameTh}
                                      //! DATALAYER
                                      eventDataLayer={{
                                        type: DATALAYER_TYPE.TRACK_POSITION,
                                        router: router?.pathname,
                                        position: 'ใน:menubar',
                                        section: 'header'
                                      }}
                                    />
                                  </ul>
                                </div>
                              ) : null}
                            </>
                          ) : (
                            <a
                              href={`/${item.link}`}
                              onMouseOver={() => setSubToggle({ status: false, key: null })}
                              //! DATALAYER
                              onClick={() =>
                                sendDataLayerGlobal({
                                  type: DATALAYER_TYPE.TRACK_POSITION,
                                  router: router?.pathname,
                                  position: 'on:header',
                                  section: 'header',
                                  data: {
                                    title: item?.nameTh,
                                    heading: item?.nameTh
                                  }
                                })
                              }
                            >
                              {item.nameTh}
                            </a>
                          )}
                        </li>
                      ))}
                    </ul>
                    <button
                      className='col-auto mobile-menu'
                      onClick={() => {
                        setMoreMenu(!moreMenu);
                        setExpandMobile(true);
                      }}
                    >
                      <FaGripHorizontal />
                    </button>
                    {expandMobile ? (
                      <button className='col-auto mobile-menu --close' onClick={() => setExpandMobile(false)}>
                        <FaTimes />
                      </button>
                    ) : (
                      <div className='col-auto mobile-menu show-mobile widget-login-wrapper'>{SHOW_MEMBER === 'true' && <WidgetLogin sessionSevId={sessionSevId} />}</div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className={`expand-more-menu ${moreMenu ? '--hide' : ''} ${expandMobile ? '--mobile' : ''}`} ref={moreMenuBoxRef}>
              {expandMobile && (
                <ul className='mobile'>
                  {!_.isEmpty(newNavData) &&
                    newNavData.map((item, i) => (
                      <li
                        key={i}
                        onClick={() => {
                          setSubToggleMoreMenu({ status: true, key: i });
                          setSubToggle({ status: false, key: null });
                        }}
                      >
                        {item.sub.length > 0 ? (
                          <>
                            <a
                              key={i}
                              href={`/${item.link}`}
                              //! DATALAYER
                              onClick={() =>
                                sendDataLayerGlobal({
                                  type: DATALAYER_TYPE.TRACK_POSITION,
                                  router: router?.pathname,
                                  position: 'on:header',
                                  section: 'header',
                                  data: {
                                    title: item?.nameTh,
                                    heading: `${item?.nameTh}`
                                  }
                                })
                              }
                            >
                              {item.nameTh}
                            </a>
                            {item.sub.length > 0 && subToggleMoreMenu.status && subToggleMoreMenu.key === i ? (
                              <FaAngleUp onClick={() => setSubToggleMoreMenu({ key: null, status: false })} />
                            ) : (
                              <FaAngleDown />
                            )}
                            {subToggleMoreMenu.status && subToggleMoreMenu.key === i ? (
                              <div className='expand-sub-mobile'>
                                <ul>
                                  {item.sub.map((subMenu, index) => (
                                    <li key={index}>
                                      <a
                                        href={subMenu.link}
                                        //! DATALAYER
                                        onClick={() =>
                                          sendDataLayerGlobal({
                                            type: DATALAYER_TYPE.TRACK_POSITION,
                                            router: router?.pathname,
                                            position: 'on:header',
                                            section: 'header',
                                            data: {
                                              title: subMenu?.nameTh,
                                              heading: `${item?.nameTh}:${subMenu?.nameTh}`
                                            }
                                          })
                                        }
                                      >
                                        {subMenu.nameTh}
                                      </a>
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            ) : null}
                          </>
                        ) : (
                          <a
                            href={`/${item.link}`}
                            //! DATALAYER
                            onClick={() =>
                              sendDataLayerGlobal({
                                type: DATALAYER_TYPE.TRACK_POSITION,
                                router: router?.pathname,
                                position: 'on:header',
                                section: 'header',
                                data: {
                                  title: item?.nameTh,
                                  heading: `${item?.nameTh}`
                                }
                              })
                            }
                          >
                            {item.nameTh}
                          </a>
                        )}
                      </li>
                    ))}
                </ul>
              )}
            </div>
          </div>
        </div>
        {toggleSearch && (
          <div className='search-box-popup is-gray'>
            {recaptchaBox && (
              <div className='recaptcha-box'>
                <ReCAPTCHA ref={recaptchaRef} sitekey={RECAPTCHA_SIT_KEY} onChange={onReCAPTCHAChange} />
              </div>
            )}
            <form onSubmit={handleSearch} className='search-box-input' ref={searchBoxRef}>
              <input type='text' maxLength={150} onChange={e => setTextSearch(e.target.value)} className='search-input' placeholder='ค้นหา' />
              <button type='submit' aria-label='Search Submit' className='search-submit'>
                <FaSearch size={24} />
              </button>
            </form>
          </div>
        )}
      </header>
      </HeaderWrapper>
    </div>
    <HeaderMB className='header-mobile'>
      <button
        className='col-auto mobile-menu'
        onClick={() => {
          setMoreMenu(!moreMenu);
          setExpandMobile(true);
        }}
      >
        <FaBars />
      </button>
       <a
        href='/'
        title='กรุงเทพธุรกิจ'
        //! DATALAYER
        onClick={() =>
          sendDataLayerGlobal({
            type: DATALAYER_TYPE.TRACK_POSITION,
            router: router?.pathname,
            position: 'on:header',
            section: 'header',
            data: {
              title: 'logo',
              heading: 'logo'
            }
          })
        }
      >
        <img className='logo' src={`${APP_IMG}/logo-primary.png`} alt='logo' width='100%' height='100%' />
      </a>
      {expandMobile ? (
        <button className='col-auto mobile-menu --close' onClick={() => setExpandMobile(false)}>
          <FaTimes />
        </button>
      ) : (
        <div className='col-auto mobile-menu show-mobile widget-login-wrapper'>{SHOW_MEMBER === 'true' && <WidgetLogin sessionSevId={sessionSevId} />}</div>
      )}
      <ExpandMore className={`expand-more-menu ${moreMenu ? '--hide' : ''} ${expandMobile ? '--mobile' : ''}`} ref={moreMenuBoxRef}>
              {expandMobile && (
                <ul className='mobile'>
                  {!_.isEmpty(newNavData) &&
                    newNavData.map((item, i) => (
                      <li
                        key={i}
                        onClick={() => {
                          setSubToggleMoreMenu({ status: true, key: i });
                          setSubToggle({ status: false, key: null });
                        }}
                      >
                        {item.sub.length > 0 ? (
                          <>
                            <a
                              key={i}
                              href={`/${item.link}`}
                              //! DATALAYER
                              onClick={() =>
                                sendDataLayerGlobal({
                                  type: DATALAYER_TYPE.TRACK_POSITION,
                                  router: router?.pathname,
                                  position: 'on:header',
                                  section: 'header',
                                  data: {
                                    title: item?.nameTh,
                                    heading: `${item?.nameTh}`
                                  }
                                })
                              }
                            >
                              {item.nameTh}
                            </a>
                            {item.sub.length > 0 && subToggleMoreMenu.status && subToggleMoreMenu.key === i ? (
                              <FaAngleUp onClick={() => setSubToggleMoreMenu({ key: null, status: false })} />
                            ) : (
                              <FaAngleDown />
                            )}
                            {subToggleMoreMenu.status && subToggleMoreMenu.key === i ? (
                              <div className='expand-sub-mobile'>
                                <ul>
                                  {item.sub.map((subMenu, index) => (
                                    <li key={index}>
                                      <a
                                        href={subMenu.link}
                                        //! DATALAYER
                                        onClick={() =>
                                          sendDataLayerGlobal({
                                            type: DATALAYER_TYPE.TRACK_POSITION,
                                            router: router?.pathname,
                                            position: 'on:header',
                                            section: 'header',
                                            data: {
                                              title: subMenu?.nameTh,
                                              heading: `${item?.nameTh}:${subMenu?.nameTh}`
                                            }
                                          })
                                        }
                                      >
                                        {subMenu.nameTh}
                                      </a>
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            ) : null}
                          </>
                        ) : (
                          <a
                            href={`/${item.link}`}
                            //! DATALAYER
                            onClick={() =>
                              sendDataLayerGlobal({
                                type: DATALAYER_TYPE.TRACK_POSITION,
                                router: router?.pathname,
                                position: 'on:header',
                                section: 'header',
                                data: {
                                  title: item?.nameTh,
                                  heading: `${item?.nameTh}`
                                }
                              })
                            }
                          >
                            {item.nameTh}
                          </a>
                        )}
                      </li>
                    ))}
                </ul>
              )}
            </ExpandMore>
      </HeaderMB>
    </>
  );
};
const HeaderWrapper = styled.header`
  width: 100%;
  font-family: 'Mitr', sans-serif;
  background-color: #fff;
  color: #000;
  margin: auto;
  padding: 0px;
  align-items: center;
  z-index: 25;
  .is-gray {
    filter: ${props => (props.gray ? 'grayscale(1)' : '')};
  }
`;
const ExpandMore = styled.div`
    background-color: #0600E7;
    color: #fff;
    position: absolute;
    z-index: 1001;
    top: 70px;
    left: 0;
    width: 100%;
    box-shadow: 2px 5px 7px rgba(0, 0, 0, 0.1);

    &.--mobile {
      width: 100%;
    }

    ul {
      max-width: 1280px;
      margin: auto;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      padding: 5px 0px;

      li {
        a {
          color: #fff;
          font-weight: 400;
        }
      }

      &.mobile {
        position: relative;
        padding: 15px;
        overflow-y: scroll;

        li {
          //display: flex;
          //justify-content: space-between;
          width: 100%;
          margin-bottom: 15px;
          color: #fff;
          border-bottom: 1px solid #0a2e58;
          list-style-type: none;

          &:hover {
            a {
              color: #909090;
            }
          }

          svg {
            float: right;
            background-color: #fff;
            color: #0a2e58;
          }

          .expand-sub-mobile {
            ul {
              display: flex;
              flex-direction: row;
              flex-wrap: wrap;
              justify-content: flex-start;
              margin: 0px auto;
              &:hover {
                color: #2f2f2f;
              }
              padding-left: 40px;

              li {
                width: auto;
                padding: 3px 15px;
                border-bottom: none;
                border-radius: 6px;
                margin: 3px 6px;
                font-weight: 400;
                background-color: #fff;
                color: #0f0d0d;
                list-style-type: none;
                &:hover {
                  color: #fff;
                  background: #000;

                  a {
                    color: #fff;
                  }
                }

                a {
                  color: #0f0d0d;
                }
              }
            }
          }
        }
      }
    }

    &.--hide {
      display: none;
    }
  
`;
const HeaderMB = styled.div`
  display: none;
  .show-mobile {
    display: block !important;
    overflow-x: hidden;
    @media (min-width: 1080px) {
      display: none !important;
    }
    #widget-login {
      padding-left: 0;
    }
  }
  .widget-login-wrapper {
    @media (max-width: 1080px) {
      overflow: unset;
    }
  }
  @media (max-width: 1080px) {
    display: flex;
    position: sticky;
    top: 0;
    width: 100vw;
    background-color: #0600E7;
    justify-content: space-around;
    align-items: center;
    z-index: 100;
    min-height: 70px;
    .logo { 
      filter: brightness(0) invert(1);
      padding-top: 5px;
      width: 45vw;
      max-width: 300px;
    }
  }
  .btn-login {
    margin-top: 3px;
    svg {
      color: #fff;
      &:hover {
        color: #fff;
      }
   }
  }
  .mobile-menu {
    background: transparent;
    border: unset;
    padding: 0;
    svg {
      color: #fff;
      font-size: 24px;
    }
  }
  .--close {
      svg {
        padding: 0 !important;
        color: #ec1818 !important;
      }
    }

`;
export default Header;
